import React from 'react';
import Img from 'gatsby-image';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { localizeDate } from '../../lib/helpers';
import OutlineBtnLg from '../Buttons/OutlineBtnLg';
import SpotifyEmbed from './SpotifyEmbed';
import PortableText from '../portableText';

const LatestRecording = ({ latestRecording, lang, btnText, latestRecordingTitle }) => {
  const {
    publishedAt,
    title,
    slug,
    imageOnly,
    spotifyUrl,
    _rawDescription: description,
  } = latestRecording;

  const truncateString = (myString, maxLength) => {
    const index = myString.indexOf('\n', maxLength);
    return index === -1 ? myString : myString.substring(0, index);
  };

  const localeReleaseDate = localizeDate(publishedAt, lang, 'short', null);
  return (
    <Container fluid>
      <Row>
        <Col
          md={6}
          className="pb-5 pb-md-0"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <StyledContainerTitle>{latestRecordingTitle}</StyledContainerTitle>
          <StyledTextContainer className="pr-4">
            <time>{localeReleaseDate}</time>
            <h3>{title}</h3>
            <div>{truncateString(description[0].children[0].text, 300)}</div>
          </StyledTextContainer>
          <OutlineBtnLg btnText={btnText} toUrl={slug.current} type="primary" />
        </Col>
        <Col md={6}>
          <Img fluid={imageOnly.asset.fluid} />
          {spotifyUrl ? <SpotifyEmbed albumUrl={spotifyUrl} height={80} /> : ''}
        </Col>
      </Row>
    </Container>
  );
};

const StyledContainerTitle = styled.h2`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.primary};
  font-weight: 400;
  margin-bottom: 1.5rem;
`;

const StyledTextContainer = styled.div`
  margin-bottom: 2rem;

  & > time {
    color: ${(props) => props.theme.colors.white};
    font-weight: 300;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  & > h3 {
    color: ${(props) => props.theme.colors.white};
    margin-bottom: 1.5rem;
  }
  & > div {
    color: ${(props) => props.theme.colors.white};
    white-space: pre-line;
  }
`;

export default LatestRecording;
