import React from 'react';
import { Link, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import Masonry from 'react-masonry-css';

import { mapEdgesToNodes } from '../lib/helpers';
import { useLoadMore } from '../lib/hooks';

import localize from '../components/localize';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHero from '../components/PageHero';
import PageFooter from '../components/PageFooter';
import FeaturedSection from '../components/FeaturedSection';
import LatestRecording from '../components/Recording/LatestRecording';
import RecordingCard from '../components/Recording/RecordingCard';
import OutlineBtnLg from '../components/Buttons/OutlineBtnLg';

const RecordingsPage = ({ data, pageContext, path }) => {
  const pageArray = mapEdgesToNodes(data.page);
  const pageInfo = pageArray[0];

  const {
    viewRecording,
    loadMoreRecordings,
    latestRecording: latestRecordingTitle,
  } = data.recordingsDictionary;

  const recordingsArray = mapEdgesToNodes(data.recordings);
  const latestRecording = recordingsArray[0];
  const earlierRecordings = recordingsArray.slice(1, recordingsArray.length);
  const numOfItemsToDisplay = 6;

  const [earlierRecordingsList, loadMore, hasMore, setLoadMore] = useLoadMore(
    earlierRecordings,
    numOfItemsToDisplay
  );

  // Handle 'load more' button click
  const handleLoadMoreClick = () => {
    setLoadMore(true);
  };

  // Responsive breakpoints for Masonry component
  const breakpointColumnObj = {
    default: 3,
    992: 2,
    500: 1,
  };
  return (
    <Layout pageContext={pageContext} path={path} siteTitle={data.site.siteTitle}>
      <SEO title={pageInfo.title} image={latestRecording.imageOnly.asset.fluid} />
      <header>
        <PageHero withImage fluidImg={pageInfo.heroImg.asset.fluid} pageTitle={pageInfo.title} />
      </header>
      <FeaturedSection>
        <LatestRecording
          latestRecording={latestRecording}
          lang={pageContext.locale}
          btnText={viewRecording}
          latestRecordingTitle={latestRecordingTitle ? latestRecordingTitle : 'Aktuelle Aufnahme'}
        />
      </FeaturedSection>

      <Container as="section" style={{ marginTop: '4.875rem' }}>
        <Masonry
          breakpointCols={breakpointColumnObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {earlierRecordingsList.map((recording) => {
            return (
              <RecordingCard
                key={recording.id}
                recording={recording}
                lang={pageContext.locale}
                btnText={viewRecording}
              />
            );
          })}
        </Masonry>
      </Container>
      <PageFooter>
        {hasMore ? (
          <OutlineBtnLg
            btnText={loadMoreRecordings}
            as="button"
            handleClick={handleLoadMoreClick}
          />
        ) : (
          <OutlineBtnLg btnText={loadMoreRecordings} as="button" disabled />
        )}
      </PageFooter>
    </Layout>
  );
};

export const query = graphql`
  query RecordingsPageQuery {
    site: sanitySiteSettings {
      siteTitle
      siteUrl
    }
    page: allSanityPage(filter: { id: { eq: "-bec422d0-6822-5b98-b1dc-abfd568abc82" } }) {
      edges {
        node {
          id
          title {
            _type
            de
            en
          }
          heroImg {
            asset {
              seoImg: fluid {
                src
              }
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    recordingsDictionary: sanityDictionaryRecording {
      latestRecording {
        _type
        de
        en
      }
      viewRecording {
        _type
        de
        en
      }
      loadMoreRecordings {
        _type
        de
        en
      }
    }
    recordings: allSanityRecording(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          title {
            _type
            de
            en
          }
          publishedAt
          slug {
            current
          }
          imageOnly {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          _rawDescription(resolveReferences: { maxDepth: 5 })
          spotifyUrl
        }
      }
    }
  }
`;

//     latest: sanityRecording(latest: { eq: true }) {
//       id
//       slug {
//         current
//       }
//       title {
//         _type
//         de
//         en
//       }
//       publishedAt(formatString: "DD MMM YYYY")
//       spotifyUrl
//       imageOnly {
//         asset {
//           fluid {
//             ...GatsbySanityImageFluid
//           }
//         }
//       }
//     }
//     recordings: allSanityRecording(sort: { fields: publishedAt, order: DESC }) {
//       edges {
//         node {
//           id
//           slug {
//             current
//           }
//           title {
//             _type
//             de
//             en
//           }
//           spotifyUrl
//           publishedAt(formatString: "DD MMM YYYY")
//           imageOnly {
//             asset {
//               fluid {
//                 ...GatsbySanityImageFluid
//               }
//             }
//           }
//         }
//       }
//     }

export default localize(RecordingsPage);
